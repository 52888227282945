<template>
  <div class="layout">
      <div class="box">
        <h1 class="title">为眼镜联网</h1>
        <!--s: account-->
        <div class="account-box">
          <div class="layout-login-username">
            <div class="layout-login-item">
              <Input prefix="ios-wifi" type="text" v-model="userInfo.userName" size="large" placeholder="请输入WiFi账号"/>
            </div>
          </div>
          <div class="layout-login-password" >
            <div class="layout-login-item">
              <Input prefix="ios-lock-outline" type="password" password v-model="userInfo.password" size="large" placeholder="请输入WiFi密码"/>
            </div>
          </div>
        </div>
        <!--e: account-->
        <!--s: submit-->
        <div class="layout-login-submit">
          <Button type="primary" size="large" long  @click="onQuickWiFi">
            <span>生成二维码</span>
          </Button>
        </div>
        <!--e: submit-->
      </div>
      <Modal
        v-model="codeVisible"
        footer-hide
        :closable="false"
      >
        <h1 class="title1">联网二维码</h1>
        <h2 class="title2">请使用眼镜打开[ 扫一扫 ]后，对准二维码扫描</h2>
        <vue-qr :logoSrc="logoSrc" :text="QRCode" :size="500" class="layout-code"></vue-qr>
      </Modal>
  </div>
</template>

<script>
import Vue from 'vue'
import {Message,Button, Input, Icon,Modal } from 'iview';
Vue.component('Button', Button);
Vue.component('Input', Input);
Vue.component('Icon', Icon);
Vue.component('Modal', Modal);
import vueQr from 'vue-qr'
export default {
    name: "Index",
    components: {
      vueQr
    },
    data(){
        return{
            QRCode:'',
            codeVisible:false,
            logoSrc:require("@/assets/logo-img.png"),
            userInfo:{
                userName:'',
                password:'',
            }
        }
    },
  mounted() {
    document.title = '眼镜联网'
  },
  methods:{
        /**
         * 获取WiFi密码
         */
        onQuickWiFi(){
            if(this.userInfo.userName == ''){
                Message.error('请输入WiFi账号！')
                return;
            }
            // if(this.userInfo.password == ''){
            //     Message.error('请输入密码！')
            //     return;
            // }
            let data = {"T":"WPA","S":this.userInfo.userName,"P":this.userInfo.password}
            this.QRCode = JSON.stringify(data)
            this.codeVisible = true
        }
    },
};
</script>
<style lang="less">
.layout{
    width:7.5rem;
    margin: 0 auto;
    height:100%;

    .title{
        width:100%;
        text-align: center;
        font-size: .3rem;
      margin-bottom:.5rem;
    }

    .box{
      width:6.9rem;
      position: relative;
      top:30%;
      left:50%;
      transform: translateX(-50%);
    }
}
.layout-code{
  width:100%;
  border-radius: 6px;
}
.title1{
  float:left;
  width:100%;
  font-size: .4rem;
  text-align: center;
  color:#000;
  margin-top: .1rem;
}
.title2{
  float:left;
  width:100%;
  padding:0 .4rem;
  font-size: .26rem;
  text-align: center;
  color:#000;
  margin-top: .2rem;
}
.layout-login-item{margin-bottom:.3rem;}
.layout-login-submit{margin-bottom:.3rem;}
</style>
